<template>
  <v-app>
    <b-container fluid>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <b-col lg="12">
              <iq-card body-class="p-0">
                <template v-slot:body>
                  <div class="iq-edit-list">
                    <tab-nav :pills="true" class="iq-edit-profile d-flex">
                      <tab-nav-items class="col-md-2 p-0" :active="true" href="#personal-information" :title="$t('customer.personal_info')" />
                      <tab-nav-items class="col-md-2 p-0" :active="false" href="#personal-info" :title="$t('customer_.client_info')" />
                      <tab-nav-items class="col-md-2 p-0" :active="false" href="#personal-documents" :title="$t('customer_.client_documents')" />
                      <tab-nav-items class="col-md-2 p-0" :active="false" href="#personal-reservations" :title="$t('customer_.client_bookings')" />
                      <tab-nav-items class="col-md-2 p-0" :active="false" href="#personal-records" :title="$t('customer_.history_record')" />
                    </tab-nav>
                  </div>
                </template>
              </iq-card>
            </b-col>
            <b-col lg="12">
              <div class="iq-edit-list-data">
                <tab-content id="pills-tabContent-2">
                  <tab-content-item :active="true" id="personal-information" >
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('employee.personal_info') }}</h4>
                      </template>
                      <template v-slot:body>
                        <b-form @submit.prevent="savePersonalInformation(user)">
                          <b-form-group class="row align-items-center">
                            <b-col md="12">
                              <div class="profile-img-edit">
                                <b-img :src="user.logo" class="height-150" alt="profile-pic"/>
                                <input type="hidden" v-model="user.logo">
                                <div class="p-image">
                                  <div class="position-relative">
                                    <i class="ri-pencil-line upload-button">
                                      <input type="file" ref="file" @change="previewImage(user)" class="h-100 position-absolute" accept="image/*" style="opacity: 0; left: 0" />
                                    </i>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-form-group>
                          <b-row align-v="center">
                            <b-form-group class="col-md-6" :label="$t('customer.customer_name')" label-for="name">
                              <ValidationProvider name="Customer name" rules="required" v-slot="{ errors }">
                                <b-form-input v-model="user.name" type="text" :placeholder="$t('customer.customer_name')" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                <div class="invalid-feedback">
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </ValidationProvider>
                            </b-form-group>
                            <b-form-group class="col-sm-6">
                              <label for="contact_no">{{ $t('customer.contact_no') }}:</label>
                              <b-form-input id="contact_no" type="text" v-model="user.phone"></b-form-input>
                            </b-form-group>
                            <b-form-group class="col-sm-6">
                              <label for="email">{{ $t('customer.email') }}:</label>
                              <b-form-input id="email" type="text" v-model="user.email"></b-form-input>
                            </b-form-group>
                            <b-form-group class="col-sm-6">
                              <label for="cnp">{{ $t('customer_.cnp') }}:</label>
                              <b-form-input id="cnp" type="text" v-model="user.cnp"></b-form-input>
                            </b-form-group>

                            <b-form-group class="col-sm-12 mt-4" :label="$t('customer.password')">
                              <b-form-input id="password" type="password" v-model="user.password"></b-form-input>
                            </b-form-group>

                          </b-row>
                          <b-button type="submit" variant="primary" class="mr-2" >{{ $t('customer.save') }}</b-button>
                        </b-form>
                      </template>
                    </iq-card>
                  </tab-content-item>
                  <tab-content-item :active="false" id="personal-records">
                    <iq-card>
                      <div class="row">
                        <div class="col-sm-3">
                          <v-list-item-group v-model="selectedItemRecord" color="primary" >
                            <v-list-item v-for="(item, i) in itemsPatientRecords" :key="i" >
                              <v-list-item-content @click="readCurrentRecord(item)">
                                <i class="ri-file-list-fill" style="display: contents"></i> {{ item.updated_at }}
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </div>
                        <div class="col-sm-9">
                          <vue-editor :disabled="true" v-model="recordData" v-show="recordId !== ''" id="editor_record"></vue-editor>
                          <br>
                          <b-button size="sm" variant="warning" class="ml-1" v-show="recordId !== ''" @click="printRecord1">{{ $t('customer_.print') }}</b-button>
                        </div>
                      </div>
                    </iq-card>
                  </tab-content-item>
                  <tab-content-item :active="false" id="personal-reservations">
                    <iq-card>
                      <div>
                        <v-timeline>
                          <v-timeline-item v-for="(year, i) in years" :key="i" :color="year.color" small >
                            <template v-slot:opposite>
                              <span :class="`headline font-weight-bold ${year.color}--text`" v-text="year.start" ></span>
                            </template>
                            <div class="py-2">
                              <h2 :class="`headline font-weight-light mb-1 ${year.color}--text`">
                                {{ year.employee_name }}: {{ year.service }}
                                <div v-if="year.diagnostic">Diagnostic: {{ year.diagnostic }}</div>
                              </h2>
                              <div>
                                {{ year.description }}
                              </div>
                            </div>
                          </v-timeline-item>
                        </v-timeline>
                      </div>
                    </iq-card>
                  </tab-content-item>
                  <tab-content-item :active="false" id="personal-documents">
                    <iq-card>
                      <!--<FileManager />-->
                      <div class="text-right">
                        <v-progress-linear v-if="uploadStarted" color="green" indeterminate ></v-progress-linear>
                        <i class="ri-eye-fill" v-if="fileClicked && fileClicked.length > 0" @click="openFileForPreview(addCustomerToken)" style="font-size: 20px; margin-right: 20px; cursor: pointer;"></i>
                        <i class="ri-delete-bin-line" v-if="fileClicked && fileClicked.length > 0" @click="deleteFile(addCustomerToken)" style="font-size: 20px; margin-right: 20px; cursor: pointer;"></i>
                        <i class="ri-upload-2-fill" style="font-size: 20px; cursor: pointer;">
                          <input type="file" ref="file4upload" @change="uploadFile(addCustomerToken)" class="h-100 position-absolute" accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt" style="opacity:0; margin-left:-20px; width:20px;" />
                        </i>
                      </div>
                      <v-treeview
                        style="max-height: 500px; overflow: auto;"
                        v-model="tree"
                        :items="items"
                        activatable
                        item-key="name"
                        open-on-click
                        @update:active="getTheFileClicked"
                      >
                        <template v-slot:prepend="{ item, open }">
                          <v-icon v-if="!item.file">
                            {{ open ? 'ri-folder-open-line' : 'ri-folder-line' }}
                          </v-icon>
                          <v-icon v-else>
                            {{ files[item.file] }}
                          </v-icon>
                        </template>
                      </v-treeview>
                    </iq-card>
                  </tab-content-item>
                  <tab-content-item :active="false" id="personal-info">
                    <iq-card>
                      <vue-editor id="editor1" v-model="editor1Content"></vue-editor>
                      <br>
                      <button type="submit" class="btn btn-primary" v-on:click="saveObservations(addCustomerToken)">{{ $t('customer.save') }}</button>
                    </iq-card>
                  </tab-content-item>
                </tab-content>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-container>
  </v-app>
</template>
<script>
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
import { VueEditor } from 'vue2-editor'
// import FileManager from '@/views/User/FileManager'

export default {
  name: 'ProfileEdit',
  components: {
    VueEditor
    // FileManager
  },
  mounted () {
    core.index()
    this.getCustomerDetails()
    this.getHistoryRecord()
    this.displayLastBookings()
    this.displayDocuments()
    this.getObservations()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      user: {
        name: '',
        email: '',
        password: '',
        logo: require('../../assets/images/user/user-11.png'),
        cnp: ''
      },
      selectedItemRecord: '',
      recordData: '',
      recordId: '',
      itemsPatientRecords: [],
      years: [],
      uploadStarted: false,
      fileClicked: '',
      files: {
        html: 'ri-html5-line',
        pdf: 'ri-file-pdf-line',
        png: 'ri-image-fill',
        jpg: 'ri-image-fill',
        gif: 'ri-image-fill',
        txt: 'ri-file-text-line',
        doc: 'ri-file-text-line',
        docs: 'ri-file-text-line',
        xls: 'ri-file-excel-2-line',
        zip: 'ri-folder-zip-fill'
      },
      tree: [],
      items: [],
      editor1Content: ''
    }
  },
  methods: {
    openFileForPreview () {
      const postData = {
        filename: this.fileClicked,
        company: this.companyToken,
        customer: this.$route.params.id
      }
      Axios.post(API.API_PREVIEW_FILE, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            window.open(response.data.path, '_blank').focus()
            this.fileClicked = []
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    deleteFile () {
      this.$bvModal.msgBoxConfirm(this.$t('customer_.delete_file_question'))
        .then(value => {
          if (value === true) {
            const postData = {
              filename: this.fileClicked,
              company: this.companyToken,
              customer: this.$route.params.id
            }
            Axios.post(API.API_DELETE_FILE, postData)
              .then((response) => {
                if (response.data.status === 'fail') {
                  core.showSnackbar('error', response.data.message)
                } else if (response.data.status === 'success') {
                  this.items = response.data.filelist
                  this.fileClicked = []
                }
              })
              .catch((error) => {
                if (error.response && error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    },
    uploadFile () {
      const maxAllowedSize = 5 * 1024 * 1024
      if (this.$refs.file4upload.files[0].size > maxAllowedSize) {
        alert(this.$t('customer_.file_big'))
      } else {
        this.uploadStarted = true
        const formData = new FormData()
        formData.append('theFile', this.$refs.file4upload.files[0])
        formData.append('company', this.companyToken)
        formData.append('customer', this.$route.params.id)
        Axios.post(API.API_ADD_CUSTOMER_FILES, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            if (response.data.status === 'fail') {
              core.showSnackbar('error', response.data.message)
            } else if (response.data.status === 'success') {
              this.items = response.data.filelist
              this.fileClicked = []
              this.uploadStarted = false
            } else if (response.data.status === 'upgrade') {
              this.$emit('upgrade_message', response.data.nextPlan)
              this.$bvModal.show('globalUpgrade')
              this.uploadStarted = false
            }
          })
          .catch((error) => {
            this.fileClicked = []
            console.error(error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
            this.uploadStarted = false
          })
      }
    },
    getTheFileClicked (data) {
      this.fileClicked = data
    },
    saveObservations () {
      const postData = {
        company: this.companyToken,
        customer: this.$route.params.id,
        observation: this.editor1Content
      }
      Axios.post(API.API_UPDATE_CUSTOMER_OBSERVATION, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('customer_.info_updated'))
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getObservations () {
      const postData = {
        company: this.companyToken,
        customer: this.$route.params.id
      }
      Axios.post(API.API_GET_CUSTOMER_OBSERVATION, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.editor1Content = response.data.observation
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    printRecord1 () {
      const customCSS = '<style>.ql-align-center{text-align: center}</style>'
      const winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0')
      winPrint.document.write('<br /><br /><br />' + this.recordData + customCSS)
      winPrint.document.close()
      winPrint.focus()
      winPrint.print()
      winPrint.close()
    },
    readCurrentRecord (item) {
      this.recordId = item.token
      this.recordData = item.record
    },
    getCustomerDetails () {
      const dataPost = {
        customer: this.$route.params.id || null
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_CUSTOMER, dataPost)
        .then((response) => {
          this.user = response.data.customer
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    savePersonalInformation (item) {
      const formData = new FormData()
      if (this.$refs.file.files[0]) {
        formData.append('file', this.$refs.file.files[0])
      }
      formData.append('name', item.name)
      formData.append('phone', item.phone)
      formData.append('email', item.email)
      formData.append('cnp', item.cnp)
      formData.append('password', item.password)
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_CUSTOMER, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('customer.user_updated'))
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    previewImage: function (task) {
      const reader = new FileReader()
      reader.onload = (e) => {
        task.logo = e.target.result
      }
      reader.readAsDataURL(this.$refs.file.files[0])
    },
    getHistoryRecord () {
      const postData = {
        company: this.companyToken,
        patient: this.$route.params.id
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_PATIENT_RECORD, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.itemsPatientRecords = response.data.templates
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    displayLastBookings () {
      const postData = {
        company: this.companyToken,
        customer: this.$route.params.id
      }
      Axios.post(API.API_GET_CUSTOMER_BOOKINGS, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.years = response.data.bookings
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    displayDocuments () {
      const postData = {
        company: this.companyToken,
        customer: this.$route.params.id
      }
      Axios.post(API.API_GET_CUSTOMER_FILES, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.items = response.data.filelist
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  }
}
</script>
